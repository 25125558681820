export default {
	es: {
		alerts : {
			"0" : {
				"titulo" : "Lo sentimos",
				"mensaje" : "Ya estas apuntad@ al evento",
			},
			"register-0" : {
				"titulo" : "Lo sentimos",
				"mensaje" : "Este usuario ya está registrado",
			},
			"ex-1" : {
				"titulo" : "Lo sentimos",
				"mensaje" : "Este usuario no existe",
			},
			"1000" : {
				"titulo" : "Lo sentimos",
				"mensaje" : "Ha ocurrido un error",
			},
			"1001" : {
				"titulo" : "Enhorabuena",
				"mensaje" : "Te has dado de alta correctamente. Te hemos enviado un email para confirmar tu correo electrónico.",
			},
			"1002" : {
				"titulo" : "Email enviado",
				"mensaje" : "Revisa tu bandeja de entrada",
			},
			"1003" : {
				"titulo" : "Enhorabuena",
				"mensaje" : "Usuario guardado correctamente",
			},
			"1004" : {
				"titulo" : "Atención",
				"mensaje" : "Usuario o contraseña incorrectos",
			},
			"1005" : {
				"titulo" : "Atención",
				"mensaje" : "Familiar añadido correctamente",
			},
			"1006" : {
				"titulo" : "Atención",
				"mensaje" : "Evento cancelado correctamente",
			},
			"1007" : {
				"titulo" : "Atención",
				"mensaje" : "Ya estas inscrito a este evento",
			},
			"1008" : {
				"titulo" : "Enhorabuena",
				"mensaje" : "Cuenta activada",
			},
			"1009" : {
				"titulo" : "Enviado correctamente",
				"mensaje" : "Pronto nos pondremos en contacto contigo",
			},
			"1010" : {
				"titulo" : "Enhorabuena",
				"mensaje" : "Tu contraseña se ha cambiado correctamente",
			},
			"1011" : {
				"titulo" : "Enhorabuena",
				"mensaje" : "Te has inscrito correctamente",
			},
			"1012" : {
				"titulo" : "¡Atención!",
				"mensaje" : "Si has marcado este botón es porque has cursado un grado, un postgrado o la ELU en la UFV.",
			},

		},
		confirmmessages : {
			'borraracademico' : {
				titulo : '¡Atención!',
				mensaje : 'Vas a borrar un dato académico, este proceso es irreversible'
			},
			'borrarprofesional' : {
				titulo : '¡Atención!',
				mensaje : 'Vas a borrar una experiencia profesional, este proceso es irreversible'
			},
			'borraridioma' : {
				titulo : '¡Atención!',
				mensaje : 'Vas a borrar un idioma, este proceso es irreversible'
			},
			'borrarfamiliar' : {
				titulo : '¡Atención!',
				mensaje : 'Vas a borrar un familiar, este proceso es irreversible'
			},
		}
	},
	en: {
	}
}
