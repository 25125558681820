<template>
  <div id="app">
    <header-app v-if="$route.name != 'index'"></header-app>
    <router-view />
    <footer-app v-if="$route.name != 'index'"></footer-app>
    <alert></alert>
    <perfil-menu v-if="isLogged"></perfil-menu>
    <popup-complete v-if="completeProfile & !!!$route.meta.hideCompleta"></popup-complete>
  </div>
</template>


<script>
  import footer from '@/components/footer.vue';
  import header from '@/components/header.vue';
  import alert from './components/alert';
  import perfilmenu from './components/perfil-menu';
  import popupcomplete from './components/popup-complete';

  import { mapGetters, mapActions } from 'vuex'


  export default {
    name: 'App',
    mounted() {
      setTimeout(() => {
        if (this.isLogged) {
          this.initapp();
        }
      }, 500);

    },
    computed: {
      ...mapGetters({
        isLogged: 'getLogged',
        completeProfile : 'getCompleteprofile'
      })
    },
    methods: {
      ...mapActions(['initapp']),
    },
    components: {
      'footer-app': footer,
      'header-app': header,
      'alert': alert,
      'perfil-menu': perfilmenu,
      'popup-complete' : popupcomplete
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&family=Source+Sans+Pro:wght@400;600;700&display=swap&family=Libre+Baskerville');
  @import '@/assets/css/colors.scss';
  @import '@/assets/css/style.scss';
  @import '@/assets/css/responsive.scss';
  @import '@/assets/css/font-awesome.scss';
</style>