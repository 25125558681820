<template>
    <div class="wrapper-index column-between">

        <a href="/home#equipo-alumni" class="enlace-cover"></a>

        <img class="logo-index" data="fadeInDown" src="/img/logo-nuevo.png" alt="Logo">

        <div class="contenedor-bottom-index">

            <nav class="menu-index row-center fww" data="fadeInUp" :show="showmenu">
                <a class="cerrar-popup material-icons" @click="showmenu = false">✕</a>
                <a class="logo-movil" href="/"><img width="140px" src="/img/logo-azul.svg" alt=""></a>
                <router-link to="/home#equipo-alumni" @mouseover.native="shownext" data="equipo">Equipo Alumni <i
                        class="fa fa-chevron-down" aria-hidden="true"></i></router-link>
                <div class="submenu-el equipo" @mouseleave="hide">
                    <router-link to="/home#quienes-somos">Quiénes somos</router-link>
                    <router-link to="/home#donde-estamos">Dónde estamos</router-link>
                </div>
                <router-link to="/home#vida-alumni" @mouseover.native="shownext" data="vida">Vida Alumni <i
                        class="fa fa-chevron-down" aria-hidden="true"></i></router-link>
                <div class="submenu-el vida" @mouseleave="hide">
                    <router-link to="/home#entrevistas">Entrevistas</router-link>
                    <router-link to="/home#eventos">Eventos</router-link>
                    <router-link to="/home#noticias">Noticias</router-link>
                </div>
                <router-link to="/home#benefits">Benefits</router-link>
                <router-link to="/home#empleo">Empleo</router-link>
                <router-link to="/home#emprendedores">Emprendedores</router-link>

                <router-link to="contacto" id="aContacto">Contacto</router-link>
                <router-link to="/login">Login</router-link>
            </nav>

            <a @click="showmenu = true" class="menu-show row-center"><span><i></i><i></i><i></i></span>Menú</a>



            <div class="redes-sociales" data="fadeInUp">
                <p class="title-colabora">AYUDA A TU UNIVERSIDAD</p>
                <div class="box-menu-colabora parpadea">
                    <router-link to="/es/general/colabora" class="buttonColabora">
                        Colabora
                    </router-link>
                </div>
                <li><a target="_blank" href="https://www.facebook.com/AlumniUFV"><i class="fa fa-facebook"></i></a></li>
                <li><a target="_blank" href="https://twitter.com/alumniufv"><i class="fa fa-twitter"></i></a></li>
                <li><a target="_blank" href="https://www.instagram.com/alumniufv"><i class="fa fa-instagram"></i></a>
                </li>
                <li><a target="_blank" href="https://vimeo.com/alumniufv"><i class="fa fa-vimeo"></i></a></li>
                <li><a target="_blank" href="https://www.linkedin.com/groups/4966122/"><i
                            class="fa fa-linkedin"></i></a></li>
            </div>
        </div>

        <div class="enlaces-legales-index column-center" data="fadeInUp">
            <div>
                <router-link target="_blank" to="/es/general/politica-privacidad">Política de privacidad</router-link>
                <router-link target="_blank" to="/es/general/aviso-legal">Aviso legal</router-link>
                <router-link target="_blank" to="/es/general/politica-cookies">Política de Cookies</router-link>
            </div>
            <div>
                <a target="_blank" href="https://www.ufv.es">Universidad Francisco de Vitoria</a>
            </div>
        </div>

    </div>
</template>



<script>
    export default {
        name: 'index',
        data : () => ({
            showmenu : false
        }),
        methods: {
            shownext(event){

                document.getElementsByClassName("submenu-el").forEach(e => {
                    e.classList.remove("show");
                });

                event.target.nextSibling.classList.add("show");
            },
            hide(){
                document.getElementsByClassName("submenu-el").forEach(e => {
                    e.classList.remove("show");
                });
            }
        }
    }
</script>