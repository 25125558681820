<template>
    <div>

        <div class="nav-top row-between">

            <a class="menu-show row-start" @click="showmenu = !showmenu"><span><i></i><i></i><i></i></span>Menú</a>
        
            <h1>
                <router-link to="/home"><img src="/img/logo_alumni_hand.png" alt=""></router-link>
            </h1>
        
            <div class="contenedor-opciones-sesion">
        
                <router-link to="/login" v-if="!isLogged"  class="js-show-login login">Login</router-link> 
                <a  v-else class="js-show-perfil login" @click="toggleMenuPerfil">Mi perfil</a>
        
            </div>
        
        </div>
        
        <div class="menu column-between aifs" :active="showmenu">
        
            <a class="cerrar-menu">✕</a>
        
            <a class="logo-container" href="/index "><img src="/img/logo-nuevo-azul.png" class="logo"></a>
        
            <nav class="real-menu column-start">
                <a class="equipo-alumni show-more" @click="showequipoalumni = !showequipoalumni">Equipo Alumni</a>
                <a v-if="showequipoalumni" class="submenu" href="/home#quienes-somos" data="equipo-alumni">Quiénes somos</a>
                <a v-if="showequipoalumni" class="submenu" href="/home#donde-estamos" data="equipo-alumni">Dónde estamos</a>
        
                <a class="vida-alumni show-more" @click="showvidaalumni = !showvidaalumni">Vida Alumni</a>
                <a v-if="showvidaalumni" class="submenu" href="/home#entrevistas" data="vida-alumni">Entrevistas</a>
                <a v-if="showvidaalumni" class="submenu" href="/home#eventos" data="vida-alumni">Eventos</a>
                <a v-if="showvidaalumni" class="submenu" href="/home#noticias" data="vida-alumni">Noticias</a>
        
                <router-link class="benefits" to="/home#benefits">Benefits</router-link>
                <router-link class="empleo" to="/home#empleo">Empleo</router-link>
                <router-link class="emprendedores" to="/home#emprendedores">Emprendedores</router-link>
                <router-link class="contacto" id="aContacto"  to="/contacto">Contacto</router-link>
                <router-link class="colabora" to="/es/general/colabora">Colabora</router-link>
                <a class="webUFV" href="https://www.ufv.es/" target="_blank">Web UFV</a> 
        
            </nav>
        
            <ul class="social row-center">
                <li><a target="_blank" href="https://www.facebook.com/AlumniUFV"><i class="fa fa-facebook"></i></a></li>
                <li><a target="_blank" href="https://twitter.com/alumniufv"><i class="fa fa-twitter"></i></a></li>
                <li><a target="_blank" href="https://www.instagram.com/alumniufv"><i class="fa fa-instagram"></i></a></li>
                <li><a target="_blank" href="https://vimeo.com/alumniufv"><i class="fa fa-vimeo"></i></a></li>
                <li><a target="_blank" href="https://www.linkedin.com/groups/4966122/"><i class="fa fa-linkedin"></i></a></li>
            </ul>
        </div>
        
        <div class="cover-menu" :active="showmenu" @click="showmenu = !showmenu"></div>

    </div>
    
</template>


<script>

    import {mapGetters,mapActions} from 'vuex';

    export default{
        name : 'header-app',
        data : () => ({
            showmenu : false,
            showvidaalumni : false,
            showequipoalumni : false
        }),
        methods :{
            ...mapActions(['toggleMenuPerfil'])
        },
        computed : {
            ...mapGetters({
                isLogged : 'getLogged'
            })
        },
        watch : {
            '$route'(){
                this.showmenu = false;
            }
        }
    }
</script>