<template>
    <div :show="!!code || !!message" class="alert-container">


        <div class="alert">
            <a class="close" @click="eraseAlertCode">✕</a>
            <template v-if="!!code">
                <h2>{{$t(`alerts['${code}'].titulo`)}}</h2>
                <p>{{$t(`alerts['${code}'].mensaje`)}}</p>
            </template>
            <template v-if="!!message">
                <template v-for="(m,key) in message">
                    <h2 :key="'title-'+key">{{m.level}}</h2>
                    <p :key="'message-'+key">{{m.message}}</p>
                </template>
            </template>
        </div>

    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'alert',
        computed: {
            ...mapGetters({
                code: 'getAlertCode',
                message: 'getAlertMessage'
            })
        },
        methods: {
            ...mapActions(['eraseAlertCode'])
        },
        watch: {
            'code'() {
                if (this.code) {
                    setTimeout(() => {
                        this.eraseAlertCode();
                    }, 15000)
                }
            },
            'message'() {
                if (this.message) {
                    setTimeout(() => {
                        // this.eraseAlertCode();
                    }, 15000)
                }
            }
        }

    }
</script>


<style lang="scss" scoped>
    .alert-container {
        position: fixed;
        right: 0;
        top: -300px;
        display: inline-block;
        width: 100%;
        max-width: 350px;
        padding: 20px;
        padding-bottom: 30px;
        transition: 0.7s;
        z-index: 50;

        .alert {
            background: white;
            padding: 20px;
            width: 100%;
            max-width: 320px;
            display: inline-block;
            box-shadow: 4px 4px 7px -2px #949494;
            text-align: left;
            border-radius: 10px;
            position: relative;
            overflow: hidden;

            .close {
                z-index: 3;
                top: 10px;
                right: 10px;
                position: absolute;
            }

            &::before {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                content: "";
                display: inline-block;
                height: 10px;
                background: #003466;
                transform: scaleX(0);
                transition-timing-function: ease-in-out;
                transform-origin: left;
            }

            h2 {
                font-weight: bold;
                margin-bottom: 10px;
                display: inline-block;
                opacity: 0;
                text-transform: capitalize;
            }

            p {
                opacity: 0;
            }
        }

        &[show="true"] {
            transform: translateY(300px);

            .alert {
                &::before {
                    transform: scaleX(1);
                    transition: 12s;
                    transition-timing-function: ease-in-out;
                    transform-origin: left;
                }

                h2,
                p {
                    opacity: 1;
                }
            }
        }
    }
</style>