<template>
    <div>
        <div class="own-select -autocomplete" v-if="formtype == 'select'" :show="show" :selected="!!value" :fill="!!valuelabel" :loading="loading">
            <select id="nivel" name="nivel" v-model="valuelabel" class="pretty" :class="classes" @change="setData(valuelabel)">
                <option name="myBrowser" disabled placeholder="" value="">{{ label }}</option>
                <option :key="key" v-for="(nivel, key) in data" name="myBrowser" :value="nivel">
                    {{ nivel.text }}</option>
            </select>

        </div>
        <div class="own-select -autocomplete" v-if="formtype != 'select'" :show="show" :selected="!!value" :fill="!!valuelabel" :loading="loading">


            <label :id="name" @click="set">{{ label }}</label>
            <input :for="name" :name="name" type="text" v-model="valuelabel" :fill="!!selected" @click="set" @keyup="downtime" autocomplete="off" @focus="show = true">
            <ul class="options" v-if="show" @mouseleave="show = false">
                <li v-for="(option, key) in data" :key="key" :active="selected == key">
                    <input type="radio" :name="name" :value="option.id" :id="name + '-' + key" v-model="selected" @change="setData(option)">
                    <label :for="name + '-' + key">{{ option.text }}</label>
                </li>
                <li v-if="data.length == 0">
                    0 resultados
                </li>
            </ul>

        </div>
    </div>
</template>


<script>

    import { mapActions } from 'vuex';

    export default {
        name: 'own-select',
        props: ['formtype', 'name', 'label', 'value', 'type', 'idkey', 'notfill'],
        mounted() {
            this.requestStudy();
        },
        data: () => ({
            selected: '',
            valuelabel: '',
            show: false,
            data: null,
            loading: false,
            new: true
        }),
        methods: {
            ...mapActions(['getAutocomplete']),
            setData(option) {
                this.show = false;
                // this.valuelabel = option.text;
                this.$emit('input', option.id);
            },
            downtime(event) {
                let time = 400;
                let forbiddenkeys = ['Enter', 'Home', 'Shift', 'CapsLock', 'Control', 'º'];

                this.new = false;

                if (!forbiddenkeys.some(k => k == event.key)) {
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        this.requestStudy();
                    }, time);
                }


            },
            requestStudy() {

                let data = {
                    type: this.type,
                    term: this.valuelabel
                }
                this.loading = true;


                this.getAutocomplete(data).then(r => {

                    this.data = r.sort((a, b) => { return (a.text < b.text) ? -1 : 1; });
                    this.loading = false;

                    if (r.length == 1 && !this.notfill) {
                        this.setData(r[0]);
                    }

                    if (!!this.value && this.new) {
                        
                        this.valuelabel = this.data.filter(e => e.id == this.value[this.idkey])[0].text
                        
                    }

                });
            },
            set() {
                this.show = true;
                document.querySelector(`*[name=${this.name}]`).focus();
            },
            erase() {
                this.show = false;
                this.valuelabel = '';
                setTimeout(() => {
                    this.requestStudy();
                }, 500)
            }
        }
    }
</script>